import { isDevelopment } from '../config/config';
import { logEvent } from '../config/firebase';

// Log the error to Firebase Analytics
const logErrorToAnalytics = (error={}, errorInfo={}, addtionalLog = {}) => {
  if(isDevelopment){
    console.error(error, errorInfo, addtionalLog);
    return;
  };
  logEvent('exception', {
    description: error.message || 'Unknown error',
    component_stack: errorInfo.componentStack || errorInfo.source || '',
    ...addtionalLog
  });
};

const logAxiosErrorToAnalytics = (err = {}, { filename, fnName })=>{
  if (err.response) {
    const {data, status, headers} = err.response || {};
    logErrorToAnalytics(null, null, {data, status, headers, filename, fnName});
  } else if (err.request) {
    logErrorToAnalytics(null, null, { request: err.request, filename, fnName});
  } else {
    logErrorToAnalytics(err, null, {filename, fnName});
  }
  logErrorToAnalytics(err.config, null, { filename, fnName });
}

export {logErrorToAnalytics, logAxiosErrorToAnalytics};
