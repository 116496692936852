import axios from "axios";
import { url } from "../../../config/config";
import { commonAction } from "../../actionTypes";
import { logAxiosErrorToAnalytics } from "../../../utils/logError";
import { getFileAndFnNameObj as  _getFileAndFnNameObj} from "../../../utils/getFileAndFnNameObj";

const getFileAndFnNameObj = _getFileAndFnNameObj("commonAction.js");

export const resendVerifyMailInit = ()=>({
    type: commonAction.RESEND_VERIFY_EMAIL_INIT
});

export const resendVerifyMailSuccess = ()=>({
    type: commonAction.RESEND_VERIFY_EMAIL_SUCCESS
});

export const resendVerifyMailFailed = (data = {})=>({
    type: commonAction.RESEND_VERIFY_EMAIL_FAILED,
    payload: data
});

/**
 * Function to resend a verification email.
 *
 * @param {Object} payload - The data required for resending the email.
 * @param {string} payload.token - The unique token for verification.
 * @param {boolean} payload.isProvider - Indicates whether the user is a provider.
 */
export function resendVerifyMail(payload){
    return dispatch => {
        dispatch(resendVerifyMailInit());
        return new Promise((resolve, reject)=>{
            if(!payload.token){
                return reject("Token cannot be undefined or null.");
            }
            axios.post(`${url}resend-verify-email`, payload)
            .then(response=>{
                const { data } = response;

                if(data?.code === 200){
                    dispatch(resendVerifyMailSuccess());
                    resolve(data);
                }else{
                    dispatch(resendVerifyMailFailed(data));
                    reject(data);
                }
            })
            .catch(err=>{
                console.log(err);
                dispatch(resendVerifyMailFailed(err.data));
                logAxiosErrorToAnalytics(err, getFileAndFnNameObj("resendVerifyMail"));
                reject(err.data);
            })
        })
    }
}