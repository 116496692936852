export const authAction = {
    MANUFACTURER_LIST_INITIATE: 'MANUFACTURER_LIST_INITIATE',
    MANUFACTURER_LIST_SUCCESS: 'MANUFACTURER_LIST_SUCCESS',
    MANUFACTURER_LIST_FAILURE: 'MANUFACTURER_LIST_FAILURE',

    MODEL_LIST_INITIATE: 'MODEL_LIST_INITIATE',
    MODEL_LIST_SUCCESS: 'MODEL_LIST_SUCCESS',
    MODEL_LIST_FAILURE: 'MODEL_LIST_FAILURE',

    CLIENT_EMAIL_CHECK_INITIATE: 'CLIENT_EMAIL_CHECK_INITIATE',
    CLIENT_EMAIL_CHECK_SUCCESS: 'CLIENT_EMAIL_CHECK_SUCCESS',
    CLIENT_EMAIL_CHECK_FAILURE: 'CLIENT_EMAIL_CHECK_FAILURE',

    CLIENT_ADDRESS_CHECK_INITIATE: 'CLIENT_ADDRESS_CHECK_INITIATE',
    CLIENT_ADDRESS_CHECK_SUCCESS: 'CLIENT_ADDRESS_CHECK_SUCCESS',
    CLIENT_ADDRESS_CHECK_FAILURE: 'CLIENT_ADDRESS_CHECK_FAILURE',

    CLIENT_SIGNUP_INITIATE: 'CLIENT_SIGNUP_INITIATE',
    CLIENT_SIGNUP_SUCCESS: 'CLIENT_SIGNUP_SUCCESS',
    CLIENT_SIGNUP_FAILURE: 'CLIENT_SIGNUP_FAILURE',

    CLIENT_SIGNUP_DETAIL_INITIATE: 'CLIENT_SIGNUP_DETAIL_INITIATE',
    CLIENT_SIGNUP_DETAIL_SUCCESS: 'CLIENT_SIGNUP_DETAIL_SUCCESS',
    CLIENT_SIGNUP_DETAIL_FAILURE: 'CLIENT_SIGNUP_DETAIL_FAILURE',

    CLIENT_LOGIN_INITIATE: 'CLIENT_LOGIN_INITIATE',
    CLIENT_LOGIN_SUCCESS: 'CLIENT_LOGIN_SUCCESS',
    CLIENT_LOGIN_FAILURE: 'CLIENT_LOGIN_FAILURE',

    CLIENT_FORGOT_INITIATE: 'CLIENT_FORGOT_INITIATE',
    CLIENT_FORGOT_SUCCESS: 'CLIENT_FORGOT_SUCCESS',
    CLIENT_FORGOT_FAILURE: 'CLIENT_FORGOT_FAILURE',

    CLIENT_RESET_INITIATE: 'CLIENT_RESET_INITIATE',
    CLIENT_RESET_SUCCESS: 'CLIENT_RESET_SUCCESS',
    CLIENT_RESET_FAILURE: 'CLIENT_RESET_FAILURE',

    CLIENT_LOGOUT_INITIATE: 'CLIENT_LOGOUT_INITIATE',
    CLIENT_LOGOUT_SUCCESS: 'CLIENT_LOGOUT_SUCCESS',
    CLIENT_LOGOUT_FAILURE: 'CLIENT_LOGOUT_FAILURE',

    GET_CLIENT_DETAILS_INITIATE: 'GET_CLIENT_DETAILS_INITIATE',
    GET_CLIENT_DETAILS_SUCCESS: 'GET_CLIENT_DETAILS_SUCCESS',
    GET_CLIENT_DETAILS_FAILURE: 'GET_CLIENT_DETAILS_FAILURE',

    CLIENT_UPDATE_INITIATE: 'CLIENT_UPDATE_INITIATE',
    CLIENT_UPDATE_SUCCESS: 'CLIENT_UPDATE_SUCCESS',
    CLIENT_UPDATE_FAILURE: 'CLIENT_UPDATE_FAILURE',

    CLIENT_PASSWORD_UPDATE_INITIATE: 'CLIENT_PASSWORD_UPDATE_INITIATE',
    CLIENT_PASSWORD_UPDATE_SUCCESS: 'CLIENT_PASSWORD_UPDATE_SUCCESS',
    CLIENT_PASSWORD_UPDATE_FAILURE: 'CLIENT_PASSWORD_UPDATE_FAILURE',
}

export const authCorporateAction = {
    MANUFACTURER_LIST_INITIATE: 'MANUFACTURER_LIST_INITIATE',
    MANUFACTURER_LIST_SUCCESS: 'MANUFACTURER_LIST_SUCCESS',
    MANUFACTURER_LIST_FAILURE: 'MANUFACTURER_LIST_FAILURE',

    MODEL_LIST_INITIATE: 'MODEL_LIST_INITIATE',
    MODEL_LIST_SUCCESS: 'MODEL_LIST_SUCCESS',
    MODEL_LIST_FAILURE: 'MODEL_LIST_FAILURE',

    CORPORATE_EMAIL_CHECK_INITIATE: 'CORPORATE_EMAIL_CHECK_INITIATE',
    CORPORATE_EMAIL_CHECK_SUCCESS: 'CORPORATE_EMAIL_CHECK_SUCCESS',
    CORPORATE_EMAIL_CHECK_FAILURE: 'CORPORATE_EMAIL_CHECK_FAILURE',

    CORPORATE_ADDRESS_CHECK_INITIATE: 'CORPORATE_ADDRESS_CHECK_INITIATE',
    CORPORATE_ADDRESS_CHECK_SUCCESS: 'CORPORATE_ADDRESS_CHECK_SUCCESS',
    CORPORATE_ADDRESS_CHECK_FAILURE: 'CORPORATE_ADDRESS_CHECK_FAILURE',

    CORPORATE_SIGNUP_INITIATE: 'CORPORATE_SIGNUP_INITIATE',
    CORPORATE_SIGNUP_SUCCESS: 'CORPORATE_SIGNUP_SUCCESS',
    CORPORATE_SIGNUP_FAILURE: 'CORPORATE_SIGNUP_FAILURE',

    CORPORATE_SIGNUP_DETAIL_INITIATE: 'CORPORATE_SIGNUP_DETAIL_INITIATE',
    CORPORATE_SIGNUP_DETAIL_SUCCESS: 'CORPORATE_SIGNUP_DETAIL_SUCCESS',
    CORPORATE_SIGNUP_DETAIL_FAILURE: 'CORPORATE_SIGNUP_DETAIL_FAILURE',

    CORPORATE_LOGIN_INITIATE: 'CORPORATE_LOGIN_INITIATE',
    CORPORATE_LOGIN_SUCCESS: 'CORPORATE_LOGIN_SUCCESS',
    CORPORATE_LOGIN_FAILURE: 'CORPORATE_LOGIN_FAILURE',

    CORPORATE_FORGOT_INITIATE: 'CORPORATE_FORGOT_INITIATE',
    CORPORATE_FORGOT_SUCCESS: 'CORPORATE_FORGOT_SUCCESS',
    CORPORATE_FORGOT_FAILURE: 'CORPORATE_FORGOT_FAILURE',

    CORPORATE_RESET_INITIATE: 'CORPORATE_RESET_INITIATE',
    CORPORATE_RESET_SUCCESS: 'CORPORATE_RESET_SUCCESS',
    CORPORATE_RESET_FAILURE: 'CORPORATE_RESET_FAILURE',

    CORPORATE_LOGOUT_INITIATE: 'CORPORATE_LOGOUT_INITIATE',
    CORPORATE_LOGOUT_SUCCESS: 'CORPORATE_LOGOUT_SUCCESS',
    CORPORATE_LOGOUT_FAILURE: 'CORPORATE_LOGOUT_FAILURE',

    GET_CORPORATE_DETAILS_INITIATE: 'GET_CORPORATE_DETAILS_INITIATE',
    GET_CORPORATE_DETAILS_SUCCESS: 'GET_CORPORATE_DETAILS_SUCCESS',
    GET_CORPORATE_DETAILS_FAILURE: 'GET_CORPORATE_DETAILS_FAILURE',

    CORPORATE_UPDATE_INITIATE: 'CORPORATE_UPDATE_INITIATE',
    CORPORATE_UPDATE_SUCCESS: 'CORPORATE_UPDATE_SUCCESS',
    CORPORATE_UPDATE_FAILURE: 'CORPORATE_UPDATE_FAILURE',

    CORPORATE_PASSWORD_UPDATE_INITIATE: 'CORPORATE_PASSWORD_UPDATE_INITIATE',
    CORPORATE_PASSWORD_UPDATE_SUCCESS: 'CORPORATE_PASSWORD_UPDATE_SUCCESS',
    CORPORATE_PASSWORD_UPDATE_FAILURE: 'CORPORATE_PASSWORD_UPDATE_FAILURE',
}

export const authProviderAction = {

    SET_COUNTRY_INITIATE : 'SET_COUNTRY_INITIATE',

    PROVIDER_EMAIL_CHECK_INITIATE: 'PROVIDER_EMAIL_CHECK_INITIATE',
    PROVIDER_EMAIL_CHECK_SUCCESS: 'PROVIDER_EMAIL_CHECK_SUCCESS',
    PROVIDER_EMAIL_CHECK_FAILURE: 'PROVIDER_EMAIL_CHECK_FAILURE',

    PROVIDER_SIGNUP_INITIATE: 'PROVIDER_SIGNUP_INITIATE',
    PROVIDER_SIGNUP_SUCCESS: 'PROVIDER_SIGNUP_SUCCESS',
    PROVIDER_SIGNUP_FAILURE: 'PROVIDER_SIGNUP_FAILURE',

    PROVIDER_SIGNUP_DETAIL_INITIATE: 'PROVIDER_SIGNUP_DETAIL_INITIATE',
    PROVIDER_SIGNUP_DETAIL_SUCCESS: 'PROVIDER_SIGNUP_DETAIL_SUCCESS',
    PROVIDER_SIGNUP_DETAIL_FAILURE: 'PROVIDER_SIGNUP_DETAIL_FAILURE',

    PROVIDER_LOGIN_INITIATE: 'PROVIDER_LOGIN_INITIATE',
    PROVIDER_LOGIN_SUCCESS: 'PROVIDER_LOGIN_SUCCESS',
    PROVIDER_LOGIN_FAILURE: 'PROVIDER_LOGIN_FAILURE',

    PROVIDER_FORGOT_INITIATE: 'PROVIDER_FORGOT_INITIATE',
    PROVIDER_FORGOT_SUCCESS: 'PROVIDER_FORGOT_SUCCESS',
    PROVIDER_FORGOT_FAILURE: 'PROVIDER_FORGOT_FAILURE',

    PROVIDER_RESET_INITIATE: 'PROVIDER_RESET_INITIATE',
    PROVIDER_RESET_SUCCESS: 'PROVIDER_RESET_SUCCESS',
    PROVIDER_RESET_FAILURE: 'PROVIDER_RESET_FAILURE',

    PROVIDER_LOGOUT_INITIATE: 'PROVIDER_LOGOUT_INITIATE',
    PROVIDER_LOGOUT_SUCCESS: 'PROVIDER_LOGOUT_SUCCESS',
    PROVIDER_LOGOUT_FAILURE: 'PROVIDER_LOGOUT_FAILURE',

    GET_PROVIDER_DETAILS_INITIATE: 'GET_PROVIDER_DETAILS_INITIATE',
    GET_PROVIDER_DETAILS_SUCCESS: 'GET_PROVIDER_DETAILS_SUCCESS',
    GET_PROVIDER_DETAILS_FAILURE: 'GET_PROVIDER_DETAILS_FAILURE',

    PROVIDER_UPDATE_INITIATE: 'PROVIDER_UPDATE_INITIATE',
    PROVIDER_UPDATE_SUCCESS: 'PROVIDER_UPDATE_SUCCESS',
    PROVIDER_UPDATE_FAILURE: 'PROVIDER_UPDATE_FAILURE',

    PROVIDER_PASSWORD_UPDATE_INITIATE: 'PROVIDER_PASSWORD_UPDATE_INITIATE',
    PROVIDER_PASSWORD_UPDATE_SUCCESS: 'PROVIDER_PASSWORD_UPDATE_SUCCESS',
    PROVIDER_PASSWORD_UPDATE_FAILURE: 'PROVIDER_PASSWORD_UPDATE_FAILURE',

    GARAGE_REVIEWS_INITIATE: 'GARAGE_REVIEWS_INITIATE',
    GARAGE_REVIEWS_SUCCESS: 'GARAGE_REVIEWS_SUCCESS',
    GARAGE_REVIEWS_FAILURE: 'GARAGE_REVIEWS_FAILURE',

    DEALER_REVIEWS_INITIATE: 'DEALER_REVIEWS_INITIATE',
    DEALER_REVIEWS_SUCCESS: 'DEALER_REVIEWS_SUCCESS',
    DEALER_REVIEWS_FAILURE: 'DEALER_REVIEWS_FAILURE',

    SALES_REVIEWS_INITIATE: 'SALES_REVIEWS_INITIATE',
    SALES_REVIEWS_SUCCESS: 'SALES_REVIEWS_SUCCESS',
    SALES_REVIEWS_FAILURE: 'SALES_REVIEWS_FAILURE',

    CORPORATE_REVIEWS_INITIATE: 'CORPORATE_REVIEWS_INITIATE',
    CORPORATE_REVIEWS_SUCCESS: 'CORPORATE_REVIEWS_SUCCESS',
    CORPORATE_REVIEWS_FAILURE: 'CORPORATE_REVIEWS_FAILURE',

    REPLY_INITIATE: 'REPLY_INITIATE',
    REPLY_SUCCESS: 'REPLY_SUCCESS',
    REPLY_FAILURE: 'REPLY_FAILURE',

    MANUFACTURER_LIST_INITIATE: 'MANUFACTURER_LIST_INITIATE',
    MANUFACTURER_LIST_SUCCESS: 'MANUFACTURER_LIST_SUCCESS',
    MANUFACTURER_LIST_FAILURE: 'MANUFACTURER_LIST_FAILURE',
}

export const pageContentAction = {
    GET_PAGE_DETAILS_INITIATE: 'GET_PAGE_DETAILS_INITIATE',
    GET_PAGE_DETAILS_SUCCESS: 'GET_PAGE_DETAILS_SUCCESS',
    GET_PAGE_DETAILS_FAILURE: 'GET_PAGE_DETAILS_FAILURE',

    GET_FAQ_INITIATE: 'GET_FAQ_INITIATE',
    GET_FAQ_SUCCESS: 'GET_FAQ_SUCCESS',
    GET_FAQ_FAILURE: 'GET_FAQ_FAILURE',

    GET_CAREER_INITIATE: 'GET_CAREER_INITIATE',
    GET_CAREER_SUCCESS: 'GET_CAREER_SUCCESS',
    GET_CAREER_FAILURE: 'GET_CAREER_FAILURE',

    SAVE_CONTACT_INITIATE: 'SAVE_CONTACT_INITIATE',
    SAVE_CONTACT_SUCCESS: 'SAVE_CONTACT_SUCCESS',
    SAVE_CONTACT_FAILURE: 'SAVE_CONTACT_FAILURE',

    NEWSLETTER_SAVE_INITIATE: 'NEWSLETTER_SAVE_INITIATE',
    NEWSLETTER_SAVE_SUCCESS: 'NEWSLETTER_SAVE_SUCCESS',
    NEWSLETTER_SAVE_FAILURE: 'NEWSLETTER_SAVE_FAILURE',
}

export const clientAction = {
    
    MANUFACTURER_LIST_INITIATE: 'MANUFACTURER_LIST_INITIATE',
    MANUFACTURER_LIST_SUCCESS: 'MANUFACTURER_LIST_SUCCESS',
    MANUFACTURER_LIST_FAILURE: 'MANUFACTURER_LIST_FAILURE',

    MODEL_LIST_INITIATE: 'MODEL_LIST_INITIATE',
    MODEL_LIST_SUCCESS: 'MODEL_LIST_SUCCESS',
    MODEL_LIST_FAILURE: 'MODEL_LIST_FAILURE',
    
    VEHICLE_LIST_INITIATE: 'VEHICLE_LIST_INITIATE',
    VEHICLE_LIST_SUCCESS: 'VEHICLE_LIST_SUCCESS',
    VEHICLE_LIST_FAILURE: 'VEHICLE_LIST_FAILURE',

    GARAGE_MARKER_LIST_INITIATE: 'GARAGE_MARKER_LIST_INITIATE',
    GARAGE_MARKER_LIST_SUCCESS: 'GARAGE_MARKER_LIST_SUCCESS',
    GARAGE_MARKER_LIST_FAILURE: 'GARAGE_MARKER_LIST_FAILURE',

    PARTS_MARKER_LIST_INITIATE: 'PARTS_MARKER_LIST_INITIATE',
    PARTS_MARKER_LIST_SUCCESS: 'PARTS_MARKER_LIST_SUCCESS',
    PARTS_MARKER_LIST_FAILURE: 'PARTS_MARKER_LIST_FAILURE',

    SALES_MARKER_LIST_INITIATE: 'SALES_MARKER_LIST_INITIATE',
    SALES_MARKER_LIST_SUCCESS: 'SALES_MARKER_LIST_SUCCESS',
    SALES_MARKER_LIST_FAILURE: 'SALES_MARKER_LIST_FAILURE',

    CORPORATE_MARKER_LIST_INITIATE: 'CORPORATE_MARKER_LIST_INITIATE',
    CORPORATE_MARKER_LIST_SUCCESS: 'CORPORATE_MARKER_LIST_SUCCESS',
    CORPORATE_MARKER_LIST_FAILURE: 'CORPORATE_MARKER_LIST_FAILURE',

    VEHICLE_CREATE_INITIATE: 'VEHICLE_CREATE_INITIATE',
    VEHICLE_CREATE_SUCCESS: 'VEHICLE_CREATE_SUCCESS',
    VEHICLE_CREATE_FAILURE: 'VEHICLE_CREATE_FAILURE',

    VEHICLE_VIEW_INITIATE: 'VEHICLE_VIEW_INITIATE',
    VEHICLE_VIEW_SUCCESS: 'VEHICLE_VIEW_SUCCESS',
    VEHICLE_VIEW_FAILURE: 'VEHICLE_VIEW_FAILURE',

    VEHICLE_UPDATE_INITIATE: 'VEHICLE_UPDATE_INITIATE',
    VEHICLE_UPDATE_SUCCESS: 'VEHICLE_UPDATE_SUCCESS',
    VEHICLE_UPDATE_FAILURE: 'VEHICLE_UPDATE_FAILURE',

    VEHICLE_DELETE_INITIATE: 'VEHICLE_DELETE_INITIATE',
    VEHICLE_DELETE_SUCCESS: 'VEHICLE_DELETE_SUCCESS',
    VEHICLE_DELETE_FAILURE: 'VEHICLE_DELETE_FAILURE',

    VEHICLE_HISTORY_GARAGE_INITIATE: 'VEHICLE_HISTORY_GARAGE_INITIATE',
    VEHICLE_HISTORY_GARAGE_SUCCESS: 'VEHICLE_HISTORY_GARAGE_SUCCESS',
    VEHICLE_HISTORY_GARAGE_FAILURE: 'VEHICLE_HISTORY_GARAGE_FAILURE',

    VEHICLE_HISTORY_DEALER_INITIATE: 'VEHICLE_HISTORY_DEALER_INITIATE',
    VEHICLE_HISTORY_DEALER_SUCCESS: 'VEHICLE_HISTORY_DEALER_SUCCESS',
    VEHICLE_HISTORY_DEALER_FAILURE: 'VEHICLE_HISTORY_DEALER_FAILURE',

    GARAGE_SEARCH_INITIATE: 'GARAGE_SEARCH_INITIATE',
    GARAGE_SEARCH_SUCCESS: 'GARAGE_SEARCH_SUCCESS',
    GARAGE_SEARCH_FAILURE: 'GARAGE_SEARCH_FAILURE',

    GARAGE_VIEW_INITIATE: 'GARAGE_VIEW_INITIATE',
    GARAGE_VIEW_SUCCESS: 'GARAGE_VIEW_SUCCESS',
    GARAGE_VIEW_FAILURE: 'GARAGE_VIEW_FAILURE',

    DEALER_SEARCH_INITIATE: 'DEALER_SEARCH_INITIATE',
    DEALER_SEARCH_SUCCESS: 'DEALER_SEARCH_SUCCESS',
    DEALER_SEARCH_FAILURE: 'DEALER_SEARCH_FAILURE',

    DEALER_VIEW_INITIATE: 'DEALER_VIEW_INITIATE',
    DEALER_VIEW_SUCCESS: 'DEALER_VIEW_SUCCESS',
    DEALER_VIEW_FAILURE: 'DEALER_VIEW_FAILURE',

    SALES_SEARCH_INITIATE: 'SALES_SEARCH_INITIATE',
    SALES_SEARCH_SUCCESS: 'SALES_SEARCH_SUCCESS',
    SALES_SEARCH_FAILURE: 'SALES_SEARCH_FAILURE',

    CORPORATE_SEARCH_INITIATE: 'CORPORATE_SEARCH_INITIATE',
    CORPORATE_SEARCH_SUCCESS: 'CORPORATE_SEARCH_SUCCESS',
    CORPORATE_SEARCH_FAILURE: 'CORPORATE_SEARCH_FAILURE',

    SALES_VIEW_INITIATE: 'SALES_VIEW_INITIATE',
    SALES_VIEW_SUCCESS: 'SALES_VIEW_SUCCESS',
    SALES_VIEW_FAILURE: 'SALES_VIEW_FAILURE',

    CORPORATE_VIEW_INITIATE: 'CORPORATE_VIEW_INITIATE',
    CORPORATE_VIEW_SUCCESS: 'CORPORATE_VIEW_SUCCESS',
    CORPORATE_VIEW_FAILURE: 'CORPORATE_VIEW_FAILURE',

    REQUEST_CREATE_INITIATE: 'REQUEST_CREATE_INITIATE',
    REQUEST_CREATE_SUCCESS: 'REQUEST_CREATE_SUCCESS',
    REQUEST_CREATE_FAILURE: 'REQUEST_CREATE_FAILURE',

    REQUEST_GARAGE_LIST_INITIATE: 'REQUEST_GARAGE_LIST_INITIATE',
    REQUEST_GARAGE_LIST_SUCCESS: 'REQUEST_GARAGE_LIST_SUCCESS',
    REQUEST_GARAGE_LIST_FAILURE: 'REQUEST_GARAGE_LIST_FAILURE',

    REQUEST_SCHEDULE_INITIATE: 'REQUEST_SCHEDULE_INITIATE',
    REQUEST_SCHEDULE_SUCCESS: 'REQUEST_SCHEDULE_SUCCESS',
    REQUEST_SCHEDULE_FAILURE: 'REQUEST_SCHEDULE_FAILURE',

    REQUEST_RESCHEDULE_INITIATE: 'REQUEST_RESCHEDULE_INITIATE',
    REQUEST_RESCHEDULE_SUCCESS: 'REQUEST_RESCHEDULE_SUCCESS',
    REQUEST_RESCHEDULE_FAILURE: 'REQUEST_RESCHEDULE_FAILURE',

    REQUEST_REJECT_INITIATE: 'REQUEST_REJECT_INITIATE',
    REQUEST_REJECT_SUCCESS: 'REQUEST_REJECT_SUCCESS',
    REQUEST_REJECT_FAILURE: 'REQUEST_REJECT_FAILURE',

    REQUEST_GARAGE_UPCOMING_INITIATE: 'REQUEST_GARAGE_UPCOMING_INITIATE',
    REQUEST_GARAGE_UPCOMING_SUCCESS: 'REQUEST_GARAGE_UPCOMING_SUCCESS',
    REQUEST_GARAGE_UPCOMING_FAILURE: 'REQUEST_GARAGE_UPCOMING_FAILURE',

    REQUEST_GARAGE_ONGOING_INITIATE: 'REQUEST_GARAGE_ONGOING_INITIATE',
    REQUEST_GARAGE_ONGOING_SUCCESS: 'REQUEST_GARAGE_ONGOING_SUCCESS',
    REQUEST_GARAGE_ONGOING_FAILURE: 'REQUEST_GARAGE_ONGOING_FAILURE',

    REQUEST_GARAGE_COMPLETED_INITIATE: 'REQUEST_GARAGE_COMPLETED_INITIATE',
    REQUEST_GARAGE_COMPLETED_SUCCESS: 'REQUEST_GARAGE_COMPLETED_SUCCESS',
    REQUEST_GARAGE_COMPLETED_FAILURE: 'REQUEST_GARAGE_COMPLETED_FAILURE',

    REQUEST_PARTS_UPCOMING_INITIATE: 'REQUEST_PARTS_UPCOMING_INITIATE',
    REQUEST_PARTS_UPCOMING_SUCCESS: 'REQUEST_PARTS_UPCOMING_SUCCESS',
    REQUEST_PARTS_UPCOMING_FAILURE: 'REQUEST_PARTS_UPCOMING_FAILURE',

    REQUEST_PARTS_ONGOING_INITIATE: 'REQUEST_PARTS_ONGOING_INITIATE',
    REQUEST_PARTS_ONGOING_SUCCESS: 'REQUEST_PARTS_ONGOING_SUCCESS',
    REQUEST_PARTS_ONGOING_FAILURE: 'REQUEST_PARTS_ONGOING_FAILURE',

    REQUEST_PARTS_COMPLETED_INITIATE: 'REQUEST_PARTS_COMPLETED_INITIATE',
    REQUEST_PARTS_COMPLETED_SUCCESS: 'REQUEST_PARTS_COMPLETED_SUCCESS',
    REQUEST_PARTS_COMPLETED_FAILURE: 'REQUEST_PARTS_COMPLETED_FAILURE',

    REQUEST_SALES_UPCOMING_INITIATE: 'REQUEST_SALES_UPCOMING_INITIATE',
    REQUEST_SALES_UPCOMING_SUCCESS: 'REQUEST_SALES_UPCOMING_SUCCESS',
    REQUEST_SALES_UPCOMING_FAILURE: 'REQUEST_SALES_UPCOMING_FAILURE',

    REQUEST_SALES_ONGOING_INITIATE: 'REQUEST_SALES_ONGOING_INITIATE',
    REQUEST_SALES_ONGOING_SUCCESS: 'REQUEST_SALES_ONGOING_SUCCESS',
    REQUEST_SALES_ONGOING_FAILURE: 'REQUEST_SALES_ONGOING_FAILURE',

    REQUEST_SALES_COMPLETED_INITIATE: 'REQUEST_SALES_COMPLETED_INITIATE',
    REQUEST_SALES_COMPLETED_SUCCESS: 'REQUEST_SALES_COMPLETED_SUCCESS',
    REQUEST_SALES_COMPLETED_FAILURE: 'REQUEST_SALES_COMPLETED_FAILURE',


    ACCEPT_BOOKING_INITIATE: 'ACCEPT_BOOKING_INITIATE',
    ACCEPT_BOOKING_SUCCESS: 'ACCEPT_BOOKING_SUCCESS',
    ACCEPT_BOOKING_FAILURE: 'ACCEPT_BOOKING_FAILURE',

    RATING_CREATE_INITIATE: 'RATING_CREATE_INITIATE',
    RATING_CREATE_SUCCESS: 'RATING_CREATE_SUCCESS',
    RATING_CREATE_FAILURE: 'RATING_CREATE_FAILURE',

    REQUEST_PARTS_LIST_INITIATE: 'REQUEST_PARTS_LIST_INITIATE',
    REQUEST_PARTS_LIST_SUCCESS: 'REQUEST_PARTS_LIST_SUCCESS',
    REQUEST_PARTS_LIST_FAILURE: 'REQUEST_PARTS_LIST_FAILURE',

    REQUEST_SALES_LIST_INITIATE: 'REQUEST_SALES_LIST_INITIATE',
    REQUEST_SALES_LIST_SUCCESS: 'REQUEST_SALES_LIST_SUCCESS',
    REQUEST_SALES_LIST_FAILURE: 'REQUEST_SALES_LIST_FAILURE',

    REQUEST_CORPORATE_LIST_INITIATE: 'REQUEST_CORPORATE_LIST_INITIATE',
    REQUEST_CORPORATE_LIST_SUCCESS: 'REQUEST_CORPORATE_LIST_SUCCESS',
    REQUEST_CORPORATE_LIST_FAILURE: 'REQUEST_CORPORATE_LIST_FAILURE',

    GARAGE_REVIEWS_INITIATE: 'GARAGE_REVIEWS_INITIATE',
    GARAGE_REVIEWS_SUCCESS: 'GARAGE_REVIEWS_SUCCESS',
    GARAGE_REVIEWS_FAILURE: 'GARAGE_REVIEWS_FAILURE',

    DEALER_REVIEWS_INITIATE: 'DEALER_REVIEWS_INITIATE',
    DEALER_REVIEWS_SUCCESS: 'DEALER_REVIEWS_SUCCESS',
    DEALER_REVIEWS_FAILURE: 'DEALER_REVIEWS_FAILURE',

    SALES_REVIEWS_INITIATE: 'SALES_REVIEWS_INITIATE',
    SALES_REVIEWS_SUCCESS: 'SALES_REVIEWS_SUCCESS',
    SALES_REVIEWS_FAILURE: 'SALES_REVIEWS_FAILURE',
}

export const providerAction = {
    REQUEST_PENDING_INITIATE: 'REQUEST_PENDING_INITIATE',
    REQUEST_PENDING_SUCCESS: 'REQUEST_PENDING_SUCCESS',
    REQUEST_PENDING_FAILURE: 'REQUEST_PENDING_FAILURE',   

    REQUEST_AVAILABLE_INITIATE: 'REQUEST_AVAILABLE_INITIATE',
    REQUEST_AVAILABLE_SUCCESS: 'REQUEST_AVAILABLE_SUCCESS',
    REQUEST_AVAILABLE_FAILURE: 'REQUEST_AVAILABLE_FAILURE',  
    
    REQUEST_REJECT_INITIATE: 'REQUEST_REJECT_INITIATE',
    REQUEST_REJECT_SUCCESS: 'REQUEST_REJECT_SUCCESS',
    REQUEST_REJECT_FAILURE: 'REQUEST_REJECT_FAILURE',  

    REQUEST_STATUS_INITIATE: 'REQUEST_STATUS_INITIATE',
    REQUEST_STATUS_SUCCESS: 'REQUEST_STATUS_SUCCESS',
    REQUEST_STATUS_FAILURE: 'REQUEST_STATUS_FAILURE',  

    CALENDAR_LIST_INITIATE: 'CALENDAR_LIST_INITIATE',
    CALENDAR_LIST_SUCCESS: 'CALENDAR_LIST_SUCCESS',
    CALENDAR_LIST_FAILURE: 'CALENDAR_LIST_FAILURE',  

    CALENDAR_DETAIL_INITIATE: 'CALENDAR_DETAIL_INITIATE',
    CALENDAR_DETAIL_SUCCESS: 'CALENDAR_DETAIL_SUCCESS',
    CALENDAR_DETAIL_FAILURE: 'CALENDAR_DETAIL_FAILURE',
    
    DIAGNOSTIC_QUOTE_INITIATE: 'DIAGNOSTIC_QUOTE_INITIATE',
    DIAGNOSTIC_QUOTE_SUCCESS: 'DIAGNOSTIC_QUOTE_SUCCESS',
    DIAGNOSTIC_QUOTE_FAILURE: 'DIAGNOSTIC_QUOTE_FAILURE',

    MECHANIC_ASSIGN_INITIATE: 'MECHANIC_ASSIGN_INITIATE',
    MECHANIC_ASSIGN_SUCCESS: 'MECHANIC_ASSIGN_SUCCESS',
    MECHANIC_ASSIGN_FAILURE: 'MECHANIC_ASSIGN_FAILURE',

    MECHANIC_CREATE_INITIATE: 'MECHANIC_CREATE_INITIATE',
    MECHANIC_CREATE_SUCCESS: 'MECHANIC_CREATE_SUCCESS',
    MECHANIC_CREATE_FAILURE: 'MECHANIC_CREATE_FAILURE',  

    MECHANIC_DELETE_INITIATE: 'MECHANIC_DELETE_INITIATE',
    MECHANIC_DELETE_SUCCESS: 'MECHANIC_DELETE_SUCCESS',
    MECHANIC_DELETE_FAILURE: 'MECHANIC_DELETE_FAILURE',
    
    MECHANIC_EDIT_INITIATE: 'MECHANIC_EDIT_INITIATE',
    MECHANIC_EDIT_SUCCESS: 'MECHANIC_EDIT_SUCCESS',
    MECHANIC_EDIT_FAILURE: 'MECHANIC_EDIT_FAILURE',

    MECHANIC_LIST_INITIATE: 'MECHANIC_LIST_INITIATE',
    MECHANIC_LIST_SUCCESS: 'MECHANIC_LIST_SUCCESS',
    MECHANIC_LIST_FAILURE: 'MECHANIC_LIST_FAILURE',

    SCHEDULING_LIST_INITIATE: 'SCHEDULING_LIST_INITIATE',
    SCHEDULING_LIST_SUCCESS: 'SCHEDULING_LIST_SUCCESS',
    SCHEDULING_LIST_FAILURE: 'SCHEDULING_LIST_FAILURE',

    VEHICLE_LIST_INITIATE: 'VEHICLE_LIST_INITIATE',
    VEHICLE_LIST_SUCCESS: 'VEHICLE_LIST_SUCCESS',
    VEHICLE_LIST_FAILURE: 'VEHICLE_LIST_FAILURE',

    DEALER_SEARCH_INITIATE: 'DEALER_SEARCH_INITIATE',
    DEALER_SEARCH_SUCCESS: 'DEALER_SEARCH_SUCCESS',
    DEALER_SEARCH_FAILURE: 'DEALER_SEARCH_FAILURE',

    SEARCH_DEALER_DETAIL_INITIATE: 'SEARCH_DEALER_DETAIL_INITIATE',
    SEARCH_DEALER_DETAIL_SUCCESS: 'SEARCH_DEALER_DETAIL_SUCCESS',
    SEARCH_DEALER_DETAIL_FAILURE: 'SEARCH_DEALER_DETAIL_FAILURE',

    REQUEST_DEALER_CREATE_INITIATE: 'REQUEST_DEALER_CREATE_INITIATE',
    REQUEST_DEALER_CREATE_SUCCESS: 'REQUEST_DEALER_CREATE_SUCCESS',
    REQUEST_DEALER_CREATE_FAILURE: 'REQUEST_DEALER_CREATE_FAILURE',

    REQUEST_SCHEDULE_INITIATE: 'REQUEST_SCHEDULE_INITIATE',
    REQUEST_SCHEDULE_SUCCESS: 'REQUEST_SCHEDULE_SUCCESS',
    REQUEST_SCHEDULE_FAILURE: 'REQUEST_SCHEDULE_FAILURE',

    REQUEST_PARTS_LIST_INITIATE: 'REQUEST_PARTS_LIST_INITIATE',
    REQUEST_PARTS_LIST_SUCCESS: 'REQUEST_PARTS_LIST_SUCCESS',
    REQUEST_PARTS_LIST_FAILURE: 'REQUEST_PARTS_LIST_FAILURE',

    REQUEST_PARTS_BOOKING_INITIATE: 'REQUEST_PARTS_BOOKING_INITIATE',
    REQUEST_PARTS_BOOKING_SUCCESS: 'REQUEST_PARTS_BOOKING_SUCCESS',
    REQUEST_PARTS_BOOKING_FAILURE: 'REQUEST_PARTS_BOOKING_FAILURE',

    MANUFACTURER_LIST_INITIATE: 'MANUFACTURER_LIST_INITIATE',
    MANUFACTURER_LIST_SUCCESS: 'MANUFACTURER_LIST_SUCCESS',
    MANUFACTURER_LIST_FAILURE: 'MANUFACTURER_LIST_FAILURE',

    MODEL_LIST_INITIATE: 'MODEL_LIST_INITIATE',
    MODEL_LIST_SUCCESS: 'MODEL_LIST_SUCCESS',
    MODEL_LIST_FAILURE: 'MODEL_LIST_FAILURE',

    PARTS_MARKER_LIST_INITIATE: 'PARTS_MARKER_LIST_INITIATE',
    PARTS_MARKER_LIST_SUCCESS: 'PARTS_MARKER_LIST_SUCCESS',
    PARTS_MARKER_LIST_FAILURE: 'PARTS_MARKER_LIST_FAILURE',

    CLIENT_HISTORY_INITIATE: 'CLIENT_HISTORY_INITIATE',
    CLIENT_HISTORY_SUCCESS: 'CLIENT_HISTORY_SUCCESS',
    CLIENT_HISTORY_FAILURE: 'CLIENT_HISTORY_FAILURE',

    TERRITORY_INITIATE: 'TERRITORY_INITIATE',
    TERRITORY_SUCCESS: 'TERRITORY_SUCCESS',
    TERRITORY_FAILURE: 'TERRITORY_FAILURE',

    MANUFACTURER_LIST_INITIATE: 'MANUFACTURER_LIST_INITIATE',
    MANUFACTURER_LIST_SUCCESS: 'MANUFACTURER_LIST_SUCCESS',
    MANUFACTURER_LIST_FAILURE: 'MANUFACTURER_LIST_FAILURE',

    REQUEST_FUTURE_INITIATE: 'REQUEST_FUTURE_INITIATE',
    REQUEST_FUTURE_SUCCESS: 'REQUEST_FUTURE_SUCCESS',
    REQUEST_FUTURE_FAILURE: 'REQUEST_FUTURE_FAILURE',

    FUTURE_APPOINTMENT_LIST_INITIATE: 'FUTURE_APPOINTMENT_LIST_INITIATE',
    FUTURE_APPOINTMENT_LIST_SUCCESS: 'FUTURE_APPOINTMENT_LIST_SUCCESS',
    FUTURE_APPOINTMENT_LIST_FAILURE: 'FUTURE_APPOINTMENT_LIST_FAILURE',

    CALENDAR_FUTURE_DETAIL_INITIATE: 'CALENDAR_FUTURE_DETAIL_INITIATE',
    CALENDAR_FUTURE_DETAIL_SUCCESS: 'CALENDAR_FUTURE_DETAIL_SUCCESS',
    CALENDAR_FUTURE_DETAIL_FAILURE: 'CALENDAR_FUTURE_DETAIL_FAILURE',

    REQUEST_CORPORATE_PENDING_INITIATE: 'REQUEST_CORPORATE_PENDING_INITIATE',
    REQUEST_CORPORATE_PENDING_SUCCESS: 'REQUEST_CORPORATE_PENDING_SUCCESS',
    REQUEST_CORPORATE_PENDING_FAILURE: 'REQUEST_CORPORATE_PENDING_FAILURE',

    REQUEST_CORPORATE_AVAILABLE_INITIATE: 'REQUEST_CORPORATE_AVAILABLE_INITIATE',
    REQUEST_CORPORATE_AVAILABLE_SUCCESS: 'REQUEST_CORPORATE_AVAILABLE_SUCCESS',
    REQUEST_CORPORATE_AVAILABLE_FAILURE: 'REQUEST_CORPORATE_AVAILABLE_FAILURE',

    REQUEST_CORPORATE_REJECT_INITIATE: 'REQUEST_CORPORATE_REJECT_INITIATE',
    REQUEST_CORPORATE_REJECT_SUCCESS: 'REQUEST_CORPORATE_REJECT_SUCCESS',
    REQUEST_CORPORATE_REJECT_FAILURE: 'REQUEST_CORPORATE_REJECT_FAILURE',

    REQUEST_CORPORATE_LIST_INITIATE: 'REQUEST_CORPORATE_LIST_INITIATE',
    REQUEST_CORPORATE_LIST_SUCCESS: 'REQUEST_CORPORATE_LIST_SUCCESS',
    REQUEST_CORPORATE_LIST_FAILURE: 'REQUEST_CORPORATE_LIST_FAILURE',

    REQUEST_CORPORATE_BOOKING_INITIATE: 'REQUEST_CORPORATE_BOOKING_INITIATE',
    REQUEST_CORPORATE_BOOKING_SUCCESS: 'REQUEST_CORPORATE_BOOKING_SUCCESS',
    REQUEST_CORPORATE_BOOKING_FAILURE: 'REQUEST_CORPORATE_BOOKING_FAILURE',

    CORPORATE_SEARCH_INITIATE: 'CORPORATE_SEARCH_INITIATE',
    CORPORATE_SEARCH_SUCCESS: 'CORPORATE_SEARCH_SUCCESS',
    CORPORATE_SEARCH_FAILURE: 'CORPORATE_SEARCH_FAILURE',
}

export const dealerAction = {
    REQUEST_PENDING_INITIATE: 'REQUEST_PENDING_INITIATE',
    REQUEST_PENDING_SUCCESS: 'REQUEST_PENDING_SUCCESS',
    REQUEST_PENDING_FAILURE: 'REQUEST_PENDING_FAILURE',   

    REQUEST_GARAGE_PENDING_INITIATE: 'REQUEST_GARAGE_PENDING_INITIATE',
    REQUEST_GARAGE_PENDING_SUCCESS: 'REQUEST_GARAGE_PENDING_SUCCESS',
    REQUEST_GARAGE_PENDING_FAILURE: 'REQUEST_GARAGE_PENDING_FAILURE',   

    REQUEST_CORPORATE_PENDING_INITIATE: 'REQUEST_CORPORATE_PENDING_INITIATE',
    REQUEST_CORPORATE_PENDING_SUCCESS: 'REQUEST_CORPORATE_PENDING_SUCCESS',
    REQUEST_CORPORATE_PENDING_FAILURE: 'REQUEST_CORPORATE_PENDING_FAILURE',   

    REQUEST_AVAILABLE_INITIATE: 'REQUEST_AVAILABLE_INITIATE',
    REQUEST_AVAILABLE_SUCCESS: 'REQUEST_AVAILABLE_SUCCESS',
    REQUEST_AVAILABLE_FAILURE: 'REQUEST_AVAILABLE_FAILURE',
    
    REQUEST_GARAGE_AVAILABLE_INITIATE: 'REQUEST_GARAGE_AVAILABLE_INITIATE',
    REQUEST_GARAGE_AVAILABLE_SUCCESS: 'REQUEST_GARAGE_AVAILABLE_SUCCESS',
    REQUEST_GARAGE_AVAILABLE_FAILURE: 'REQUEST_GARAGE_AVAILABLE_FAILURE',

    REQUEST_CORPORATE_AVAILABLE_INITIATE: 'REQUEST_CORPORATE_AVAILABLE_INITIATE',
    REQUEST_CORPORATE_AVAILABLE_SUCCESS: 'REQUEST_CORPORATE_AVAILABLE_SUCCESS',
    REQUEST_CORPORATE_AVAILABLE_FAILURE: 'REQUEST_CORPORATE_AVAILABLE_FAILURE',
    
    REQUEST_REJECT_INITIATE: 'REQUEST_REJECT_INITIATE',
    REQUEST_REJECT_SUCCESS: 'REQUEST_REJECT_SUCCESS',
    REQUEST_REJECT_FAILURE: 'REQUEST_REJECT_FAILURE', 
    
    REQUEST_USER_INITIATE: 'REQUEST_USER_INITIATE',
    REQUEST_USER_SUCCESS: 'REQUEST_USER_SUCCESS',
    REQUEST_USER_FAILURE: 'REQUEST_USER_FAILURE', 

    REQUEST_GARAGE_INITIATE: 'REQUEST_GARAGE_INITIATE',
    REQUEST_GARAGE_SUCCESS: 'REQUEST_GARAGE_SUCCESS',
    REQUEST_GARAGE_FAILURE: 'REQUEST_GARAGE_FAILURE', 

    REQUEST_CORPORATE_INITIATE: 'REQUEST_CORPORATE_INITIATE',
    REQUEST_CORPORATE_SUCCESS: 'REQUEST_CORPORATE_SUCCESS',
    REQUEST_CORPORATE_FAILURE: 'REQUEST_CORPORATE_FAILURE', 

    REQUEST_STATUS_INITIATE: 'REQUEST_STATUS_INITIATE',
    REQUEST_STATUS_SUCCESS: 'REQUEST_STATUS_SUCCESS',
    REQUEST_STATUS_FAILURE: 'REQUEST_STATUS_FAILURE',  

    CALENDAR_LIST_INITIATE: 'CALENDAR_LIST_INITIATE',
    CALENDAR_LIST_SUCCESS: 'CALENDAR_LIST_SUCCESS',
    CALENDAR_LIST_FAILURE: 'CALENDAR_LIST_FAILURE',  

    CALENDAR_GARAGE_LIST_INITIATE: 'CALENDAR_GARAGE_LIST_INITIATE',
    CALENDAR_GARAGE_LIST_SUCCESS: 'CALENDAR_GARAGE_LIST_SUCCESS',
    CALENDAR_GARAGE_LIST_FAILURE: 'CALENDAR_GARAGE_LIST_FAILURE',  

    CALENDAR_CORPORATE_LIST_INITIATE: 'CALENDAR_CORPORATE_LIST_INITIATE',
    CALENDAR_CORPORATE_LIST_SUCCESS: 'CALENDAR_CORPORATE_LIST_SUCCESS',
    CALENDAR_CORPORATE_LIST_FAILURE: 'CALENDAR_CORPORATE_LIST_FAILURE',  

    CALENDAR_DETAIL_INITIATE: 'CALENDAR_DETAIL_INITIATE',
    CALENDAR_DETAIL_SUCCESS: 'CALENDAR_DETAIL_SUCCESS',
    CALENDAR_DETAIL_FAILURE: 'CALENDAR_DETAIL_FAILURE',

    MANUFACTURER_LIST_INITIATE: 'MANUFACTURER_LIST_INITIATE',
    MANUFACTURER_LIST_SUCCESS: 'MANUFACTURER_LIST_SUCCESS',
    MANUFACTURER_LIST_FAILURE: 'MANUFACTURER_LIST_FAILURE',

    MODEL_LIST_INITIATE: 'MODEL_LIST_INITIATE',
    MODEL_LIST_SUCCESS: 'MODEL_LIST_SUCCESS',
    MODEL_LIST_FAILURE: 'MODEL_LIST_FAILURE',

    REQUEST_FUTURE_INITIATE: 'REQUEST_FUTURE_INITIATE',
    REQUEST_FUTURE_SUCCESS: 'REQUEST_FUTURE_SUCCESS',
    REQUEST_FUTURE_FAILURE: 'REQUEST_FUTURE_FAILURE',

    CLIENT_HISTORY_INITIATE: 'CLIENT_HISTORY_INITIATE',
    CLIENT_HISTORY_SUCCESS: 'CLIENT_HISTORY_SUCCESS',
    CLIENT_HISTORY_FAILURE: 'CLIENT_HISTORY_FAILURE',

    TERRITORY_INITIATE: 'TERRITORY_INITIATE',
    TERRITORY_SUCCESS: 'TERRITORY_SUCCESS',
    TERRITORY_FAILURE: 'TERRITORY_FAILURE',

    FUTURE_APPOINTMENT_LIST_INITIATE: 'FUTURE_APPOINTMENT_LIST_INITIATE',
    FUTURE_APPOINTMENT_LIST_SUCCESS: 'FUTURE_APPOINTMENT_LIST_SUCCESS',
    FUTURE_APPOINTMENT_LIST_FAILURE: 'FUTURE_APPOINTMENT_LIST_FAILURE',

    CALENDAR_FUTURE_DETAIL_INITIATE: 'CALENDAR_FUTURE_DETAIL_INITIATE',
    CALENDAR_FUTURE_DETAIL_SUCCESS: 'CALENDAR_FUTURE_DETAIL_SUCCESS',
    CALENDAR_FUTURE_DETAIL_FAILURE: 'CALENDAR_FUTURE_DETAIL_FAILURE',
}

export const corporateAction = {
    
    MANUFACTURER_LIST_INITIATE: 'MANUFACTURER_LIST_INITIATE',
    MANUFACTURER_LIST_SUCCESS: 'MANUFACTURER_LIST_SUCCESS',
    MANUFACTURER_LIST_FAILURE: 'MANUFACTURER_LIST_FAILURE',

    MODEL_LIST_INITIATE: 'MODEL_LIST_INITIATE',
    MODEL_LIST_SUCCESS: 'MODEL_LIST_SUCCESS',
    MODEL_LIST_FAILURE: 'MODEL_LIST_FAILURE',
    
    VEHICLE_LIST_INITIATE: 'VEHICLE_LIST_INITIATE',
    VEHICLE_LIST_SUCCESS: 'VEHICLE_LIST_SUCCESS',
    VEHICLE_LIST_FAILURE: 'VEHICLE_LIST_FAILURE',

    GARAGE_MARKER_LIST_INITIATE: 'GARAGE_MARKER_LIST_INITIATE',
    GARAGE_MARKER_LIST_SUCCESS: 'GARAGE_MARKER_LIST_SUCCESS',
    GARAGE_MARKER_LIST_FAILURE: 'GARAGE_MARKER_LIST_FAILURE',

    PARTS_MARKER_LIST_INITIATE: 'PARTS_MARKER_LIST_INITIATE',
    PARTS_MARKER_LIST_SUCCESS: 'PARTS_MARKER_LIST_SUCCESS',
    PARTS_MARKER_LIST_FAILURE: 'PARTS_MARKER_LIST_FAILURE',

    SALES_MARKER_LIST_INITIATE: 'SALES_MARKER_LIST_INITIATE',
    SALES_MARKER_LIST_SUCCESS: 'SALES_MARKER_LIST_SUCCESS',
    SALES_MARKER_LIST_FAILURE: 'SALES_MARKER_LIST_FAILURE',

    VEHICLE_CREATE_INITIATE: 'VEHICLE_CREATE_INITIATE',
    VEHICLE_CREATE_SUCCESS: 'VEHICLE_CREATE_SUCCESS',
    VEHICLE_CREATE_FAILURE: 'VEHICLE_CREATE_FAILURE',

    VEHICLE_VIEW_INITIATE: 'VEHICLE_VIEW_INITIATE',
    VEHICLE_VIEW_SUCCESS: 'VEHICLE_VIEW_SUCCESS',
    VEHICLE_VIEW_FAILURE: 'VEHICLE_VIEW_FAILURE',

    VEHICLE_UPDATE_INITIATE: 'VEHICLE_UPDATE_INITIATE',
    VEHICLE_UPDATE_SUCCESS: 'VEHICLE_UPDATE_SUCCESS',
    VEHICLE_UPDATE_FAILURE: 'VEHICLE_UPDATE_FAILURE',

    VEHICLE_DELETE_INITIATE: 'VEHICLE_DELETE_INITIATE',
    VEHICLE_DELETE_SUCCESS: 'VEHICLE_DELETE_SUCCESS',
    VEHICLE_DELETE_FAILURE: 'VEHICLE_DELETE_FAILURE',

    VEHICLE_HISTORY_GARAGE_INITIATE: 'VEHICLE_HISTORY_GARAGE_INITIATE',
    VEHICLE_HISTORY_GARAGE_SUCCESS: 'VEHICLE_HISTORY_GARAGE_SUCCESS',
    VEHICLE_HISTORY_GARAGE_FAILURE: 'VEHICLE_HISTORY_GARAGE_FAILURE',

    VEHICLE_HISTORY_DEALER_INITIATE: 'VEHICLE_HISTORY_DEALER_INITIATE',
    VEHICLE_HISTORY_DEALER_SUCCESS: 'VEHICLE_HISTORY_DEALER_SUCCESS',
    VEHICLE_HISTORY_DEALER_FAILURE: 'VEHICLE_HISTORY_DEALER_FAILURE',

    GARAGE_SEARCH_INITIATE: 'GARAGE_SEARCH_INITIATE',
    GARAGE_SEARCH_SUCCESS: 'GARAGE_SEARCH_SUCCESS',
    GARAGE_SEARCH_FAILURE: 'GARAGE_SEARCH_FAILURE',

    GARAGE_VIEW_INITIATE: 'GARAGE_VIEW_INITIATE',
    GARAGE_VIEW_SUCCESS: 'GARAGE_VIEW_SUCCESS',
    GARAGE_VIEW_FAILURE: 'GARAGE_VIEW_FAILURE',

    DEALER_SEARCH_INITIATE: 'DEALER_SEARCH_INITIATE',
    DEALER_SEARCH_SUCCESS: 'DEALER_SEARCH_SUCCESS',
    DEALER_SEARCH_FAILURE: 'DEALER_SEARCH_FAILURE',

    DEALER_VIEW_INITIATE: 'DEALER_VIEW_INITIATE',
    DEALER_VIEW_SUCCESS: 'DEALER_VIEW_SUCCESS',
    DEALER_VIEW_FAILURE: 'DEALER_VIEW_FAILURE',

    SALES_SEARCH_INITIATE: 'SALES_SEARCH_INITIATE',
    SALES_SEARCH_SUCCESS: 'SALES_SEARCH_SUCCESS',
    SALES_SEARCH_FAILURE: 'SALES_SEARCH_FAILURE',

    SALES_VIEW_INITIATE: 'SALES_VIEW_INITIATE',
    SALES_VIEW_SUCCESS: 'SALES_VIEW_SUCCESS',
    SALES_VIEW_FAILURE: 'SALES_VIEW_FAILURE',

    REQUEST_CREATE_INITIATE: 'REQUEST_CREATE_INITIATE',
    REQUEST_CREATE_SUCCESS: 'REQUEST_CREATE_SUCCESS',
    REQUEST_CREATE_FAILURE: 'REQUEST_CREATE_FAILURE',

    REQUEST_GARAGE_LIST_INITIATE: 'REQUEST_GARAGE_LIST_INITIATE',
    REQUEST_GARAGE_LIST_SUCCESS: 'REQUEST_GARAGE_LIST_SUCCESS',
    REQUEST_GARAGE_LIST_FAILURE: 'REQUEST_GARAGE_LIST_FAILURE',

    REQUEST_SCHEDULE_INITIATE: 'REQUEST_SCHEDULE_INITIATE',
    REQUEST_SCHEDULE_SUCCESS: 'REQUEST_SCHEDULE_SUCCESS',
    REQUEST_SCHEDULE_FAILURE: 'REQUEST_SCHEDULE_FAILURE',

    REQUEST_RESCHEDULE_INITIATE: 'REQUEST_RESCHEDULE_INITIATE',
    REQUEST_RESCHEDULE_SUCCESS: 'REQUEST_RESCHEDULE_SUCCESS',
    REQUEST_RESCHEDULE_FAILURE: 'REQUEST_RESCHEDULE_FAILURE',

    REQUEST_REJECT_INITIATE: 'REQUEST_REJECT_INITIATE',
    REQUEST_REJECT_SUCCESS: 'REQUEST_REJECT_SUCCESS',
    REQUEST_REJECT_FAILURE: 'REQUEST_REJECT_FAILURE',

    REQUEST_GARAGE_UPCOMING_INITIATE: 'REQUEST_GARAGE_UPCOMING_INITIATE',
    REQUEST_GARAGE_UPCOMING_SUCCESS: 'REQUEST_GARAGE_UPCOMING_SUCCESS',
    REQUEST_GARAGE_UPCOMING_FAILURE: 'REQUEST_GARAGE_UPCOMING_FAILURE',

    REQUEST_GARAGE_ONGOING_INITIATE: 'REQUEST_GARAGE_ONGOING_INITIATE',
    REQUEST_GARAGE_ONGOING_SUCCESS: 'REQUEST_GARAGE_ONGOING_SUCCESS',
    REQUEST_GARAGE_ONGOING_FAILURE: 'REQUEST_GARAGE_ONGOING_FAILURE',

    REQUEST_GARAGE_COMPLETED_INITIATE: 'REQUEST_GARAGE_COMPLETED_INITIATE',
    REQUEST_GARAGE_COMPLETED_SUCCESS: 'REQUEST_GARAGE_COMPLETED_SUCCESS',
    REQUEST_GARAGE_COMPLETED_FAILURE: 'REQUEST_GARAGE_COMPLETED_FAILURE',

    REQUEST_PARTS_UPCOMING_INITIATE: 'REQUEST_PARTS_UPCOMING_INITIATE',
    REQUEST_PARTS_UPCOMING_SUCCESS: 'REQUEST_PARTS_UPCOMING_SUCCESS',
    REQUEST_PARTS_UPCOMING_FAILURE: 'REQUEST_PARTS_UPCOMING_FAILURE',

    REQUEST_PARTS_ONGOING_INITIATE: 'REQUEST_PARTS_ONGOING_INITIATE',
    REQUEST_PARTS_ONGOING_SUCCESS: 'REQUEST_PARTS_ONGOING_SUCCESS',
    REQUEST_PARTS_ONGOING_FAILURE: 'REQUEST_PARTS_ONGOING_FAILURE',

    REQUEST_PARTS_COMPLETED_INITIATE: 'REQUEST_PARTS_COMPLETED_INITIATE',
    REQUEST_PARTS_COMPLETED_SUCCESS: 'REQUEST_PARTS_COMPLETED_SUCCESS',
    REQUEST_PARTS_COMPLETED_FAILURE: 'REQUEST_PARTS_COMPLETED_FAILURE',

    ACCEPT_BOOKING_INITIATE: 'ACCEPT_BOOKING_INITIATE',
    ACCEPT_BOOKING_SUCCESS: 'ACCEPT_BOOKING_SUCCESS',
    ACCEPT_BOOKING_FAILURE: 'ACCEPT_BOOKING_FAILURE',

    RATING_CREATE_INITIATE: 'RATING_CREATE_INITIATE',
    RATING_CREATE_SUCCESS: 'RATING_CREATE_SUCCESS',
    RATING_CREATE_FAILURE: 'RATING_CREATE_FAILURE',

    REQUEST_PARTS_LIST_INITIATE: 'REQUEST_PARTS_LIST_INITIATE',
    REQUEST_PARTS_LIST_SUCCESS: 'REQUEST_PARTS_LIST_SUCCESS',
    REQUEST_PARTS_LIST_FAILURE: 'REQUEST_PARTS_LIST_FAILURE',

    GARAGE_REVIEWS_INITIATE: 'GARAGE_REVIEWS_INITIATE',
    GARAGE_REVIEWS_SUCCESS: 'GARAGE_REVIEWS_SUCCESS',
    GARAGE_REVIEWS_FAILURE: 'GARAGE_REVIEWS_FAILURE',

    DEALER_REVIEWS_INITIATE: 'DEALER_REVIEWS_INITIATE',
    DEALER_REVIEWS_SUCCESS: 'DEALER_REVIEWS_SUCCESS',
    DEALER_REVIEWS_FAILURE: 'DEALER_REVIEWS_FAILURE',

    REQUEST_SALES_LIST_INITIATE: 'REQUEST_SALES_LIST_INITIATE',
    REQUEST_SALES_LIST_SUCCESS: 'REQUEST_SALES_LIST_SUCCESS',
    REQUEST_SALES_LIST_FAILURE: 'REQUEST_SALES_LIST_FAILURE',

    SALES_REVIEWS_INITIATE: 'SALES_REVIEWS_INITIATE',
    SALES_REVIEWS_SUCCESS: 'SALES_REVIEWS_SUCCESS',
    SALES_REVIEWS_FAILURE: 'SALES_REVIEWS_FAILURE',

    REQUEST_SALES_UPCOMING_INITIATE: 'REQUEST_SALES_UPCOMING_INITIATE',
    REQUEST_SALES_UPCOMING_SUCCESS: 'REQUEST_SALES_UPCOMING_SUCCESS',
    REQUEST_SALES_UPCOMING_FAILURE: 'REQUEST_SALES_UPCOMING_FAILURE',

    REQUEST_SALES_ONGOING_INITIATE: 'REQUEST_SALES_ONGOING_INITIATE',
    REQUEST_SALES_ONGOING_SUCCESS: 'REQUEST_SALES_ONGOING_SUCCESS',
    REQUEST_SALES_ONGOING_FAILURE: 'REQUEST_SALES_ONGOING_FAILURE',

    REQUEST_SALES_COMPLETED_INITIATE: 'REQUEST_SALES_COMPLETED_INITIATE',
    REQUEST_SALES_COMPLETED_SUCCESS: 'REQUEST_SALES_COMPLETED_SUCCESS',
    REQUEST_SALES_COMPLETED_FAILURE: 'REQUEST_SALES_COMPLETED_FAILURE',
}

export const salesAction = {
    REQUEST_PENDING_INITIATE: 'REQUEST_PENDING_INITIATE',
    REQUEST_PENDING_SUCCESS: 'REQUEST_PENDING_SUCCESS',
    REQUEST_PENDING_FAILURE: 'REQUEST_PENDING_FAILURE',   

    REQUEST_AVAILABLE_INITIATE: 'REQUEST_AVAILABLE_INITIATE',
    REQUEST_AVAILABLE_SUCCESS: 'REQUEST_AVAILABLE_SUCCESS',
    REQUEST_AVAILABLE_FAILURE: 'REQUEST_AVAILABLE_FAILURE',  
    
    REQUEST_REJECT_INITIATE: 'REQUEST_REJECT_INITIATE',
    REQUEST_REJECT_SUCCESS: 'REQUEST_REJECT_SUCCESS',
    REQUEST_REJECT_FAILURE: 'REQUEST_REJECT_FAILURE',  

    REQUEST_STATUS_INITIATE: 'REQUEST_STATUS_INITIATE',
    REQUEST_STATUS_SUCCESS: 'REQUEST_STATUS_SUCCESS',
    REQUEST_STATUS_FAILURE: 'REQUEST_STATUS_FAILURE',  

    CALENDAR_LIST_INITIATE: 'CALENDAR_LIST_INITIATE',
    CALENDAR_LIST_SUCCESS: 'CALENDAR_LIST_SUCCESS',
    CALENDAR_LIST_FAILURE: 'CALENDAR_LIST_FAILURE',  

    CALENDAR_DETAIL_INITIATE: 'CALENDAR_DETAIL_INITIATE',
    CALENDAR_DETAIL_SUCCESS: 'CALENDAR_DETAIL_SUCCESS',
    CALENDAR_DETAIL_FAILURE: 'CALENDAR_DETAIL_FAILURE',
    
    DIAGNOSTIC_QUOTE_INITIATE: 'DIAGNOSTIC_QUOTE_INITIATE',
    DIAGNOSTIC_QUOTE_SUCCESS: 'DIAGNOSTIC_QUOTE_SUCCESS',
    DIAGNOSTIC_QUOTE_FAILURE: 'DIAGNOSTIC_QUOTE_FAILURE',

    MECHANIC_ASSIGN_INITIATE: 'MECHANIC_ASSIGN_INITIATE',
    MECHANIC_ASSIGN_SUCCESS: 'MECHANIC_ASSIGN_SUCCESS',
    MECHANIC_ASSIGN_FAILURE: 'MECHANIC_ASSIGN_FAILURE',

    AGENT_CREATE_INITIATE: 'AGENT_CREATE_INITIATE',
    AGENT_CREATE_SUCCESS: 'AGENT_CREATE_SUCCESS',
    AGENT_CREATE_FAILURE: 'AGENT_CREATE_FAILURE',  

    AGENT_DELETE_INITIATE: 'AGENT_DELETE_INITIATE',
    AGENT_DELETE_SUCCESS: 'AGENT_DELETE_SUCCESS',
    AGENT_DELETE_FAILURE: 'AGENT_DELETE_FAILURE',
    
    AGENT_EDIT_INITIATE: 'AGENT_EDIT_INITIATE',
    AGENT_EDIT_SUCCESS: 'AGENT_EDIT_SUCCESS',
    AGENT_EDIT_FAILURE: 'AGENT_EDIT_FAILURE',

    AGENT_LIST_INITIATE: 'AGENT_LIST_INITIATE',
    AGENT_LIST_SUCCESS: 'AGENT_LIST_SUCCESS',
    AGENT_LIST_FAILURE: 'AGENT_LIST_FAILURE',

    SCHEDULING_LIST_INITIATE: 'SCHEDULING_LIST_INITIATE',
    SCHEDULING_LIST_SUCCESS: 'SCHEDULING_LIST_SUCCESS',
    SCHEDULING_LIST_FAILURE: 'SCHEDULING_LIST_FAILURE',

    VEHICLE_LIST_INITIATE: 'VEHICLE_LIST_INITIATE',
    VEHICLE_LIST_SUCCESS: 'VEHICLE_LIST_SUCCESS',
    VEHICLE_LIST_FAILURE: 'VEHICLE_LIST_FAILURE',

    SALES_SEARCH_INITIATE: 'SALES_SEARCH_INITIATE',
    SALES_SEARCH_SUCCESS: 'SALES_SEARCH_SUCCESS',
    SALES_SEARCH_FAILURE: 'SALES_SEARCH_FAILURE',

    SEARCH_SALES_DETAIL_INITIATE: 'SEARCH_SALES_DETAIL_INITIATE',
    SEARCH_SALES_DETAIL_SUCCESS: 'SEARCH_SALES_DETAIL_SUCCESS',
    SEARCH_SALES_DETAIL_FAILURE: 'SEARCH_SALES_DETAIL_FAILURE',

    REQUEST_SALES_CREATE_INITIATE: 'REQUEST_SALES_CREATE_INITIATE',
    REQUEST_SALES_CREATE_SUCCESS: 'REQUEST_SALES_CREATE_SUCCESS',
    REQUEST_SALES_CREATE_FAILURE: 'REQUEST_SALES_CREATE_FAILURE',

    REQUEST_SCHEDULE_INITIATE: 'REQUEST_SCHEDULE_INITIATE',
    REQUEST_SCHEDULE_SUCCESS: 'REQUEST_SCHEDULE_SUCCESS',
    REQUEST_SCHEDULE_FAILURE: 'REQUEST_SCHEDULE_FAILURE',

    REQUEST_PARTS_LIST_INITIATE: 'REQUEST_PARTS_LIST_INITIATE',
    REQUEST_PARTS_LIST_SUCCESS: 'REQUEST_PARTS_LIST_SUCCESS',
    REQUEST_PARTS_LIST_FAILURE: 'REQUEST_PARTS_LIST_FAILURE',

    REQUEST_PARTS_BOOKING_INITIATE: 'REQUEST_PARTS_BOOKING_INITIATE',
    REQUEST_PARTS_BOOKING_SUCCESS: 'REQUEST_PARTS_BOOKING_SUCCESS',
    REQUEST_PARTS_BOOKING_FAILURE: 'REQUEST_PARTS_BOOKING_FAILURE',

    REQUEST_FUTURE_INITIATE: 'REQUEST_FUTURE_INITIATE',
    REQUEST_FUTURE_SUCCESS: 'REQUEST_FUTURE_SUCCESS',
    REQUEST_FUTURE_FAILURE: 'REQUEST_FUTURE_FAILURE',
}

export const vehicleAction = {
    
    MANUFACTURER_LIST_INITIATE: 'MANUFACTURER_LIST_INITIATE',
    MANUFACTURER_LIST_SUCCESS: 'MANUFACTURER_LIST_SUCCESS',
    MANUFACTURER_LIST_FAILURE: 'MANUFACTURER_LIST_FAILURE',

    MODEL_LIST_INITIATE: 'MODEL_LIST_INITIATE',
    MODEL_LIST_SUCCESS: 'MODEL_LIST_SUCCESS',
    MODEL_LIST_FAILURE: 'MODEL_LIST_FAILURE',
    
    VEHICLE_LIST_INITIATE: 'VEHICLE_LIST_INITIATE',
    VEHICLE_LIST_SUCCESS: 'VEHICLE_LIST_SUCCESS',
    VEHICLE_LIST_FAILURE: 'VEHICLE_LIST_FAILURE',

    VEHICLE_CREATE_INITIATE: 'VEHICLE_CREATE_INITIATE',
    VEHICLE_CREATE_SUCCESS: 'VEHICLE_CREATE_SUCCESS',
    VEHICLE_CREATE_FAILURE: 'VEHICLE_CREATE_FAILURE',

    VEHICLE_VIEW_INITIATE: 'VEHICLE_VIEW_INITIATE',
    VEHICLE_VIEW_SUCCESS: 'VEHICLE_VIEW_SUCCESS',
    VEHICLE_VIEW_FAILURE: 'VEHICLE_VIEW_FAILURE',

    VEHICLE_UPDATE_INITIATE: 'VEHICLE_UPDATE_INITIATE',
    VEHICLE_UPDATE_SUCCESS: 'VEHICLE_UPDATE_SUCCESS',
    VEHICLE_UPDATE_FAILURE: 'VEHICLE_UPDATE_FAILURE',

    VEHICLE_DELETE_INITIATE: 'VEHICLE_DELETE_INITIATE',
    VEHICLE_DELETE_SUCCESS: 'VEHICLE_DELETE_SUCCESS',
    VEHICLE_DELETE_FAILURE: 'VEHICLE_DELETE_FAILURE',

    VEHICLE_HISTORY_GARAGE_INITIATE: 'VEHICLE_HISTORY_GARAGE_INITIATE',
    VEHICLE_HISTORY_GARAGE_SUCCESS: 'VEHICLE_HISTORY_GARAGE_SUCCESS',
    VEHICLE_HISTORY_GARAGE_FAILURE: 'VEHICLE_HISTORY_GARAGE_FAILURE',

    VEHICLE_HISTORY_DEALER_INITIATE: 'VEHICLE_HISTORY_DEALER_INITIATE',
    VEHICLE_HISTORY_DEALER_SUCCESS: 'VEHICLE_HISTORY_DEALER_SUCCESS',
    VEHICLE_HISTORY_DEALER_FAILURE: 'VEHICLE_HISTORY_DEALER_FAILURE',
}

export const commonAction = {
    RESEND_VERIFY_EMAIL_INIT: 'RESEND_VERIFY_EMAIL_INIT',
    RESEND_VERIFY_EMAIL_SUCCESS: 'RESEND_VERIFY_EMAIL_SUCCESS',
    RESEND_VERIFY_EMAIL_FAILED: 'RESEND_VERIFY_EMAIL_FAILED',
}