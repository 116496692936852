import { commonAction } from "../../actionTypes"


const initialState = {
    loading: false,
    resendVerifySuccess: false,
    resendVerifyError: false,
}

export default function common(state = initialState, {type, payload}) {
    switch(type) {
        case commonAction.RESEND_VERIFY_EMAIL_INIT: {
            return {
              ...state, loading: true
            }
          }
        case commonAction.RESEND_VERIFY_EMAIL_SUCCESS: {
            return {
                ...state, loading: false, resendVerifySuccess: true
            }
        }
        case commonAction.RESEND_VERIFY_EMAIL_FAILED: {
            return {
                ...state, loading: false, resendVerifyError: payload
            }
        }
        default: 
            return state
    }
}