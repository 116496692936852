import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.css'
import { resendVerifyMail } from '../../../redux/actions/static/commonAction';

function VerifyEmailAlert(props) {

    const { profile, isProvider  } = props;

    const [setShowAlert, setSetShowAlert] = useState(true);

    const dispatch = useDispatch();
    const { loading, resendVerifySuccess, resendVerifyError  } = useSelector(state=> state.common);

    if(!setShowAlert){
        return null;
    }

    const handleResendVerifyEmail = async (e)=>{
        e.preventDefault();
        if(loading){
            return;
        }
        console.log("profile", profile);
        const {token} = profile;
        try {
            dispatch(resendVerifyMail({ token, isProvider }));
        } catch (error) {
            
        }
    }

    const renderResendContent = ()=>{
        console.log("Rendering....");
        if(loading){
            return <span>Please wait, mail is sending...</span>
        }
        if(resendVerifySuccess){
            return <>
                    <span>Verify mail sent successfully, please check your mail inbox</span>
                    <span pointer="true" onClick={(e)=>setSetShowAlert(false)} >X</span>
                </>
        }
        if(resendVerifyError){
            return <>
                <span>Error while sending verification mail, please try again after some time.</span>
                <span pointer="true" onClick={(e)=>setSetShowAlert(false)} >X</span>
            </>
        }

        return <>
                <span>
                    Your email is not verified please verify it. Please check your mail inbox or <a href="#" onClick={handleResendVerifyEmail} >resend verify mail</a>
                </span>
                <span pointer="true" onClick={(e)=>setSetShowAlert(false)} >X</span>
        </>
    }

  return (
    <div className='email-verification'>
        {renderResendContent()}
    </div>
  )
}

export default VerifyEmailAlert;